@tailwind base;
@tailwind components;
@tailwind utilities;

:root:has(:is(.modal-open, .modal:target, .modal-toggle:checked + .modal, .modal[open])) {
  scrollbar-gutter: unset;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@layer components {
  .form-label {
    @apply text-sm basis-2/5;
  }

  .form-text-input {
    @apply w-full input input-bordered input-primary input-sm;
  }

  .form-textarea {
    @apply w-full textarea textarea-bordered textarea-primary;
  }

  .form-toggle {
    @apply toggle toggle-sm toggle-primary;
  }
}
